import { Directive, HostListener, Output, EventEmitter, OnDestroy } from '@angular/core';

@Directive({
  selector: '[hoverState]',
})
export class HoverStateDirective implements OnDestroy {
  @Output() isHovering = new EventEmitter<boolean>();

  private isDestroyed = false;

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.isDestroyed) this.isHovering.emit(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.isDestroyed) this.isHovering.emit(false);
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.isHovering.complete(); // Complete the event emitter to avoid memory leaks
  }
}
