import { GetObjectHistoryResponse } from '@dotocean/virtualworld-ngx-services';
import moment, { Moment } from 'moment';

export function deduceHistoryDateRange(histories: GetObjectHistoryResponse[], maxHistoryDate: Moment) {
  const seenTimes = new Set<string>();
  return histories.filter((history) => {
    // Normalize the time using moment.utc and convert to ISO string
    const timeMoment = moment.utc(history.time);

    if (timeMoment > maxHistoryDate) return false;

    const normalizedTime = moment.utc(history.time).toISOString();

    if (seenTimes.has(normalizedTime)) {
      // Duplicate time found; exclude this entry
      return false;
    } else {
      // New unique time; include this entry and mark the time as seen
      seenTimes.add(normalizedTime);
      return true;
    }
  });
}
