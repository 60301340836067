import { Component, effect, inject, Input } from '@angular/core';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { VesselToFollow } from '../vessel-tofollow.class';
import { ECharts, EChartsOption } from 'echarts';
import moment from 'moment';
import { AisReplayService } from '../../../../services/ais-replay.service';
import darkTheme from '../../../../../public/themes/echart-theme-dark.json'; // Import the custom theme JSON
import lightTheme from '../../../../../public/themes/echart-theme-light.json'; // Import the custom theme JSON
import * as echarts from 'echarts';
import { ThemeService } from '../../../../services/theme-service';
import { getMaxSpeed } from '../events-detail-player/events-detail-player.component';
import { GetObjectHistoryResponse } from '@dotocean/virtualworld-ngx-services';

echarts.registerTheme('light-theme', lightTheme); // Register the theme
echarts.registerTheme('dark-theme', darkTheme); // Register the theme

@Component({
  selector: 'app-events-detail-echart',
  standalone: true,
  imports: [FleetManagerComponents, NgxEchartsDirective],
  providers: [provideEcharts()],
  templateUrl: './events-detail-echart.component.html',
  styleUrl: './events-detail-echart.component.scss',
})
export class EventsDetailEchartComponent {
  public readonly aisReplayService = inject(AisReplayService);
  public readonly themeService = inject(ThemeService);

  public currentTheme = 'light-theme';

  chartInstance: ECharts | undefined;

  private xAxisDateFormat = 'HH:mm:ss';

  private readonly markLineStyle = {
    backgroundColor: '#ffffffcc',
    color: '#2f8a56',
    fontWeight: 'bold',
    fontSize: 10,
    padding: 5,
  };

  @Input() public isBiggerChart = false;

  @Input({ required: true })
  public minimumHistoryDate: moment.Moment;

  public chartOption: EChartsOption = {
    animation: false,
    grid: {
      left: '5%',
      right: '5%',
      top: '15%',
      bottom: '15%',
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: (value: any) => {
          return moment(value).format(this.xAxisDateFormat);
          //return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`; // Custom formatting
        },
      },
    },
    yAxis: {
      name: 'Speed (kts)',
      type: 'value', // Y-axis is for speed values
      axisLabel: {
        formatter: '{value}', // Label with units (knots)
      },
      max: 16,
    },
    tooltip: {
      trigger: 'axis', // Show tooltip on hover
      formatter: (params: any) => {
        const date = moment(params[0].value[0]);
        const formattedDate = date.format(this.xAxisDateFormat);
        let result = `${formattedDate}<br/>`;
        params.forEach((item: any) => {
          result += `${item.seriesName}: ${item.value[1]}<br/>`;
        });
        return result;
      },
    },
  };

  public constructor() {
    this.themeService.activeTheme$.subscribe((theme) => {
      this.currentTheme = `${theme}-theme`;
    });
    // Update the chart when the vessels change
    effect(() => {
      this.prepareDatasets(this.aisReplayService.vessels());
    });

    // Update the markLine when the time changes (And vessels)
    effect(() => {
      this.updateMarkLine(this.aisReplayService.vessels(), this.aisReplayService.currentTime());
    });
  }

  private prepareDatasets(vessels: VesselToFollow[]) {
    if (vessels.length === 0) return;

    const series: any[] = vessels.map((vessel, index) => {
      const data = vessel.histories.map((entry) => [
        entry.time, // Convert Time to Date object
        entry.s!,
      ]);

      const vesselName = vessel.vesselInfo.name ?? '' + vessel.vesselInfo.mmsi;
      const serie = {
        name: vesselName,
        type: 'line',
        data: data,
        itemStyle: {
          color: vessel.color,
        },
        lineStyle: {
          color: vessel.color,
          width: 2,
        },
        symbol: 'none',
        markLine: !vessel.isTarget
          ? {
              //silent: true, // Disable interactions on markLine
              data: [
                {
                  xAxis: vessel.start?.toDate(), // Draw vertical line at this timestamp
                  label: {
                    formatter: () => `{style|${vesselName} ${vessel.start?.format('HH:mm:ss')}}`, // Custom label for the vertical line
                    rich: {
                      style: this.markLineStyle,
                    },
                    position: 'end',
                    offset: [0, index * 20],
                  },
                },
                {
                  xAxis: vessel.end?.toDate(), // Draw vertical line at this timestamp
                  label: {
                    formatter: () => `{style|${vesselName} ${vessel.end?.format('HH:mm:ss')}}`, // Custom label for the vertical line
                    rich: {
                      style: { ...this.markLineStyle, color: '#db163d' },
                    },
                    position: 'end',
                    offset: [0, index * 20],
                  },
                },
              ],
            }
          : {},
      };

      return serie;
    });

    const allHistories = vessels.flatMap((a) => a.histories);
    this.xAxisDateFormat = this.hasMultipleDays(allHistories) ? 'DD/MM HH:mm:ss' : 'HH:mm:ss';
    const maxSpeed = getMaxSpeed(allHistories) ?? 16;
    this.chartOption = {
      ...this.chartOption,
      yAxis: {
        type: 'value', // Y-axis is for speed values
        axisLabel: {
          formatter: '{value}', // Label with units (knots)
        },
        max: maxSpeed < 16 ? 16 : maxSpeed,
      },
      series: series,
    };
  }

  private hasMultipleDays(histories: GetObjectHistoryResponse[]): boolean {
    if (histories.length < 2 || !histories[0].time) return false;

    const firstTime = new Date(histories[0].time).setHours(0, 0, 0, 0);

    return histories.slice(1).some((history) => {
      const currentTime = new Date(history.time!).setHours(0, 0, 0, 0);
      return currentTime !== firstTime;
    });
  }

  private initialUpdateComplete = false;
  public onChartFinished() {
    if (this.initialUpdateComplete) return;
    this.initialUpdateComplete = true;
    setTimeout(() => {
      this.updateMarkLine(this.aisReplayService.vessels(), this.aisReplayService.currentTime());
    });
  }

  private chartMouseDown = false;
  public onChartInit(ec: ECharts) {
    if (this.chartInstance) return;
    this.chartInstance = ec;

    ec.getZr().on('mouseup', () => {
      this.chartMouseDown = false;
    });
    ec.getZr().on('mousemove', (params) => {
      if (this.chartMouseDown) {
        this.setCurrentTimeAccordingToMousePos(params);
      }
    });
    ec.getZr().on('mousedown', (params) => {
      if (!params.topTarget) return;
      this.chartMouseDown = true;
      this.setCurrentTimeAccordingToMousePos(params);
    });
  }

  private setCurrentTimeAccordingToMousePos(params: any): void {
    if (!params.topTarget || !this.chartInstance) return;
    // Get the pixel coordinates of the click event
    const clickX = params.offsetX;
    const clickY = params.offsetY;

    // Convert pixel coordinates to data coordinates
    const pointInGrid = this.chartInstance.convertFromPixel('grid', [clickX, clickY]);

    if (pointInGrid) {
      const xValue = pointInGrid[0]; // X-axis data value
      this.aisReplayService.currentTime.set(xValue);
    }
  }

  private updateMarkLine(vessels: VesselToFollow[], currentTime: number): void {
    if (vessels.length === 0) return;

    const firstVessel = vessels[0];
    const history = firstVessel.histories.find((point) => new Date(point.time!).getTime() > currentTime);

    // Use ViewChild to directly access the ECharts instance and update only the relevant series
    if (this.chartInstance && history) {
      // Update the scatter data (index 1 is the scatter series)
      this.chartInstance.setOption(
        {
          series: [
            {
              type: 'line',
              itemStyle: {
                color: '#86d9f0',
              },
              markLine: {
                silent: true, // Disable interactions on markLine
                symbol: ['triangle', 'none'],
                symbolSize: 15,
                symbolOffset: ['50%', '50%'],
                lineStyle: {
                  width: 0,
                  dashOffset: 5,
                },
                data: [
                  {
                    name: 'X',
                    xAxis: history?.time,
                    label: {
                      formatter: () => `{style|${moment(currentTime).format(this.xAxisDateFormat)}}`, // Custom label for the vertical line
                      rich: {
                        style: {
                          backgroundColor: '#717171',
                          color: '#86d9f0',
                          fontWeight: 'bold',
                          fontSize: 10,
                          padding: 5,
                        },
                      },
                      position: 'start',
                      offset: [-10, 0],
                    },
                  }, // Move the markLine dynamically
                ],
              },
            },
          ],
        },
        { notMerge: false }
      ); // Ensure only specific series are updated without affecting others
    }
  }
}
