import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'localDate',
  standalone: true
})
export class LocalDatePipe implements PipeTransform {
  transform(value: Date | string | undefined | moment.Moment, format = 'DD/MM/YYYY HH:mm'): string {
    if (!value) return '';

    const date = moment.utc(value).local(); // Convert UTC date to local time

    switch (format) {
      case 'short':
        return date.format('MM/DD/YYYY, h:mm A'); // Customize this format as needed
      case 'medium':
        return date.format('MMM DD, YYYY, h:mm:ss A'); // Customize this format as needed
      case 'long':
        return date.format('MMMM DD, YYYY, h:mm:ss A Z'); // Customize this format as needed
      case 'default':
        return date.format('YYYY-MM-DD HH:mm:ss'); // Customize this format as needed
      default:
        return date.format(format); // Use custom format if provided
    }
  }
}
