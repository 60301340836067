import { Injectable } from '@angular/core';
import { AlertDetailedList } from './apis/cloud.service';
import { MapTrackerData } from '../models/map-tracker-data';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class MapPopupService {
  public getObjectPopupHtml(trackerData: MapTrackerData): string {
    const lastUpdateMoment = moment(trackerData.time);
    const timeDifference = lastUpdateMoment.fromNow(); // e.g., "a few seconds ago"

    const speedType = trackerData.obj?.type === 'AIS' ? 'knots' : 'm/s or knots';

    return `<div style="font-weight: bold; color: black;  z-index:9999;">
              ${trackerData.name ?? ''}<small style="color: gray;"> (${trackerData.mmsi})</small>
          </div>
          <div style="color: black;">
              <div><b>Speed:</b> ${Math.round(trackerData.speed! * 100) / 100} ${speedType}</div>
              <div><b>Course:</b> ${Math.round(trackerData.course * 100) / 100} °</div>
          </div>
          <small>
            Last Update ${timeDifference}
          </small>
      `;
  }

  public getAlertGeofencingPopupHTML(alert: AlertDetailedList): string {
    return 'Alert geofence zone: ' + alert.name;
  }
}
