import { Geographic } from '../models/Geographic';
import * as L from 'leaflet';

export function GetRectanglePolygon(latitude: number, longitude: number, color: string, heading: number, width: number, length: number) {
  const latlngProjected = GetRectangleLatLng(latitude, longitude, heading, width, length);

  return L.polygon(latlngProjected, {
    color: 'black',
    fillColor: color,
    fillOpacity: 0.5,
    opacity: 0.4,
    weight: 2,
  });
}

export function GetRectangleLatLng(latitude: number, longitude: number, heading: number, width: number, length: number) {
  const move = (point: Geographic.xy, distance: number, degrees: number): Geographic.xy => {
    degrees = ((degrees % 360) + 360) % 360;
    const radians = (degrees * Math.PI) / 180;
    return [point[0] + distance * Math.sin(radians), point[1] + distance * Math.cos(radians)];
  };

  const projection = new Geographic.UTMProjection(Geographic.UTMProjection.getUTMZone(latitude, longitude));
  const center = projection.transform2xy([{ lat: latitude, lng: longitude }])[0];
  const topCenter = move(center, length / 2, heading);
  const topTopCenter = move(topCenter, 10, heading);
  const bottomCenter = move(center, length / 2, heading + 180);
  const topLeft = move(topCenter, width / 2, heading + 90);
  const topRight = move(topCenter, width / 2, heading - 90);
  const bottomLeft = move(bottomCenter, width / 2, heading + 90);
  const bottomRight = move(bottomCenter, width / 2, heading - 90);

  return projection.transform2LatLng([topLeft, topTopCenter, topRight, bottomRight, bottomLeft, topLeft]).map((e) => new L.LatLng(e.lat, e.lng));
}
