import { effect, Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import L, { CircleMarker, MarkerClusterGroup } from 'leaflet';
import TrackSymbol from '@arl/leaflet-tracksymbol2';
import 'leaflet.markercluster';
import { MapPopupService } from './map-popup.service';
import { MapTrackerData } from '../models/map-tracker-data';
import { VesselToFollow } from '../app/events/events-detail/vessel-tofollow.class';

export interface MapBounds {
  latSW: number;
  lngSW: number;
  latNE: number;
  lngNE: number;
}

export interface MapBoundsMinMax {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
}

export interface MapSettings {
  addObjectOverlay?: boolean;
  showLayerControl?: boolean;
  showScale?: boolean;
  showMeasurementTool?: boolean;
}

@Injectable()
export class MapService implements OnDestroy {
  public mapBounds?: MapBoundsMinMax;
  public isServiceDestroyed = false;
  public mapLoaded$ = new BehaviorSubject<boolean>(false);
  public mapLoaded = signal(false);
  public Map?: L.Map;

  private highlightCircle: L.Circle | undefined;

  private _layerControl?: L.Control.Layers;
  private readonly overlayStorageKey = 'overlays';

  private _overlays: string[] = [];

  private clusteredMarkersGroupedByAlertSource: Record<number, MarkerClusterGroup> = {};

  public selectedMapTrackerData: WritableSignal<MapTrackerData | undefined> = signal(undefined);

  constructor(private readonly mapPopupService: MapPopupService) {
    effect(() => {
      const selectedMapTracker = this.selectedMapTrackerData();
      if (selectedMapTracker) {
        this.showHighlightCircle(new L.LatLng(selectedMapTracker.lat, selectedMapTracker.lng));
        return;
      }
      this.removeHighlightCircle();
    });
  }

  private overrideDefaultMapSettings(mapSettings: MapSettings) {
    mapSettings.addObjectOverlay = mapSettings.addObjectOverlay === undefined ? true : mapSettings.addObjectOverlay;
    mapSettings.showLayerControl = mapSettings.showLayerControl === undefined ? true : mapSettings.showLayerControl;
    mapSettings.showScale = mapSettings.showScale === undefined ? true : mapSettings.showScale;
    mapSettings.showMeasurementTool = mapSettings.showMeasurementTool === undefined ? true : mapSettings.showMeasurementTool;
  }

  public async initMap(mapSettings: MapSettings, mapName = 'map') {
    this.overrideDefaultMapSettings(mapSettings);
    this.isServiceDestroyed = false;
    if (this.isServiceDestroyed) return;

    this.Map = L.map(mapName, {
      zoomControl: false,
      attributionControl: false,
    });
    this.Map.whenReady(() => {
      setTimeout(() => {
        this.Map?.invalidateSize();

        this.setBounds();

        this.Map!.createPane('vesselPane');
        // Set the z-index lower than other panes (default overlayPane has z-index 400)
        this.Map!.getPane('vesselPane')!.style.zIndex = '402';

        //This is to make sure the bounds are always set correctly
        this.subscribeTo_MoveEnd(undefined);
        this.mapLoaded$.next(true);
        this.mapLoaded.set(true);
      }, 100);
    });

    this.getOpenStreetMapLayer().addTo(this.Map);
    this.addLayers(mapSettings);
    if (mapSettings.showScale) L.control.scale({ position: 'bottomright', imperial: false }).addTo(this.Map);

    this.setViewToDotOcean();

    this.subscribeTo_Click(() => {
      this.selectedMapTrackerData.set(undefined);
    });

    this.Map?.on('zoomend', () => {
      if (this.highlightCircle) {
        this.showHighlightCircle(undefined);
      }
    });

    if (mapSettings.showMeasurementTool) {
      // this.Map.addControl(L.control.Measure({ primaryLengthUnit: 'meters', secondaryLengthUnit: 'kilometers',
      //    primaryAreaUnit: 'sqmeters', secondaryAreaUnit: undefined,
      //     activeColor: 'red'
      //  }));
    }
  }

  public centerViewToCoordinate(coordinates: [number, number]) {
    this.Map?.setView(coordinates, this.Map?.getZoom(), { animate: true });
  }

  public addToClusteredMarkers(owned: boolean | undefined, marker: L.Marker, layerGroup: L.LayerGroup<any>) {
    const numberFromSource = owned ? 1 : 0;
    let clusteredMarkers = this.clusteredMarkersGroupedByAlertSource[numberFromSource];
    if (!clusteredMarkers) {
      clusteredMarkers = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            className: 'markerVessel' + (owned ? '' : 'markerVessel-favorite'),
            iconAnchor: [23.5, 60],
            html: `<div class="markerVessel markerVessel-clustered ${
              owned ? '' : 'markerVessel-clustered-favorite'
            }"> <div class="markerVessel-content"> ${cluster.getChildCount()} </div>`,
          });
        },
      });
      layerGroup.addLayer(clusteredMarkers);
      this.clusteredMarkersGroupedByAlertSource[numberFromSource] = clusteredMarkers;
    }

    clusteredMarkers.addLayer(marker);
  }

  public removeFromClusteredMarkers(marker: L.Marker) {
    Object.keys(this.clusteredMarkersGroupedByAlertSource).forEach((key: any) => {
      if (this.clusteredMarkersGroupedByAlertSource[key].hasLayer(marker)) {
        this.clusteredMarkersGroupedByAlertSource[key].removeLayer(marker);
      }
    });
  }

  private addLayers(mapSettings: MapSettings) {
    if (!this.Map) return;

    const groups: Record<string, L.LayerGroup> = {};

    const selectedOverlays = localStorage.getItem(this.overlayStorageKey);

    if (selectedOverlays) this._overlays = JSON.parse(selectedOverlays) || [];

    if (mapSettings.addObjectOverlay) {
      this.objectGroup = new L.LayerGroup();
      const openSeaMapGroup = new L.LayerGroup();
      openSeaMapGroup.addLayer(this.getOpenSeaMapLayer());

      groups['OpenSeaMap'] = openSeaMapGroup;
      groups['Objects'] = this.objectGroup;

      if (!mapSettings.showLayerControl || !selectedOverlays || this._overlays.indexOf('Objects') > -1) this.Map.addLayer(this.objectGroup);
      if (!mapSettings.showLayerControl || !selectedOverlays || this._overlays.indexOf('OpenSeaMap') > -1) this.Map.addLayer(openSeaMapGroup);

      if (this._overlays.indexOf('Objects') == -1) this._overlays.push('Objects');
      if (this._overlays.indexOf('OpenSeaMap') == -1) this._overlays.push('OpenSeaMap');
    }

    if (mapSettings.showLayerControl) {
      this._layerControl = L.control.layers(undefined, groups, { position: 'topleft' }).addTo(this.Map);

      this.subscribeTo_OverlayAdd(undefined);
      this.subscribeTo_OverlayRemove(undefined);
    }
  }

  public objectGroup?: L.LayerGroup;

  private getOpenStreetMapLayer() {
    return L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    });
  }

  private getOpenSeaMapLayer() {
    return L.tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', {
      bounds: [
        [-90, -180],
        [90, 180],
      ],
      noWrap: true,
      maxZoom: 22,
    });
  }

  public subscribeTo_Move(fn: L.LeafletEventHandlerFn) {
    this.Map?.on('move', fn);
  }

  public subscribeTo_Click(fn: L.LeafletMouseEventHandlerFn) {
    this.Map?.on('click', fn);
  }

  public subscribeTo_MoveEnd(fn: L.LeafletEventHandlerFn | undefined) {
    this.Map?.on(
      'moveend',
      fn ??
        (async () => {
          await this.setBounds();
        })
    );
  }

  public subscribeTo_OverlayAdd(fn: L.LayersControlEventHandlerFn | undefined) {
    this.Map?.on(
      'overlayadd',
      fn ??
        ((e: any) => {
          this.addOverlay(e.name);
        })
    );
  }

  public subscribeTo_OverlayRemove(fn: L.LayersControlEventHandlerFn | undefined) {
    this.Map?.on(
      'overlayremove',
      fn ??
        ((e: any) => {
          this.removeOverlay(e.name);
        })
    );
  }

  public addOverlay(name: any) {
    if (this._overlays.indexOf(name) == -1) {
      this._overlays.push(name);
      this.saveSelectedOverlays();
    }
  }

  public removeOverlay(name: any) {
    this._overlays.splice(this._overlays.indexOf(name), 1);
    this.saveSelectedOverlays();
  }

  saveSelectedOverlays() {
    if (!this.Map) return;
    localStorage.setItem(this.overlayStorageKey, JSON.stringify(this._overlays));
  }

  public setViewToDotOcean() {
    if (!this.Map) return;
    this.Map.setView([51.20151, 3.196264], 16);
  }

  public setDefaultView() {
    if (!this.Map) return;
    this.Map.setView([51.20151, 3.196264], 1);
  }

  public removeLayer(layer: L.Layer) {
    if (this.isServiceDestroyed) return;
    this.Map?.removeLayer(layer);
  }

  public async setBounds() {
    if (!this.Map) return;

    const bounds = this.Map.getBounds();
    this.mapBounds = {
      minLat: bounds.getSouthWest().lat,
      maxLat: bounds.getNorthEast().lat,
      minLng: bounds.getSouthWest().lng,
      maxLng: bounds.getNorthEast().lng,
    };
  }

  public getBounds(): MapBounds {
    return {
      latSW: this.mapBounds?.minLat ?? 0,
      lngSW: this.mapBounds?.minLng ?? 0,
      latNE: this.mapBounds?.maxLat ?? 0,
      lngNE: this.mapBounds?.maxLng ?? 0,
    };
  }

  public Dispose() {
    this.isServiceDestroyed = true;
    if (this.Map) {
      this.Map.remove();
      this.Map = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.Dispose();
  }

  public updateTracksymbol(trackSymbol: TrackSymbol | CircleMarker, trackerData: MapTrackerData, popupHTML: string) {
    trackSymbol.setLatLng([trackerData.lat, trackerData.lng]);

    const trackSymbolIsCircleMarker = this.isCircleMarker(trackSymbol);

    if (!trackSymbolIsCircleMarker) {
      trackSymbol.setHeading((trackerData.course * Math.PI) / 180.0);
      trackSymbol.setCourse((trackerData.course * Math.PI) / 180.0);
      trackSymbol.setSpeed(trackerData.speed);
    }

    trackSymbol.getPopup()?.setContent(popupHTML);

    this.updateSelectedMapTracker(trackerData);
  }

  public addTrackerLine(
    trackLine: L.Polyline | undefined,
    trackerHistoryCoordinates: [number, number][],
    trackerLineAdded: (ts: L.Polyline) => void
  ) {
    if (!this.Map) return;

    if (!trackLine) {
      const newTrackLine = L.polyline(trackerHistoryCoordinates, {
        color: 'white',
        weight: 1,
        opacity: 0.8,
      }).addTo(this.Map);

      trackerLineAdded(newTrackLine);
      return;
    }

    trackLine.setLatLngs(trackerHistoryCoordinates);
  }

  private isCircleMarker(symbol: TrackSymbol | CircleMarker | undefined): symbol is CircleMarker {
    return symbol instanceof CircleMarker;
  }

  public addTrackerSymbol(
    trackSymbol: TrackSymbol | CircleMarker | undefined,
    trackerData: MapTrackerData,
    layerGroup: L.LayerGroup<any>,
    trackerAdded: (ts: TrackSymbol | CircleMarker) => void
  ) {
    const popupHTML = this.mapPopupService.getObjectPopupHtml(trackerData);

    const isVesselMoving = trackerData.speed && trackerData.speed > 0.2;
    const trackSymbolIsCircleMarker = this.isCircleMarker(trackSymbol);

    if (trackSymbol && ((isVesselMoving && !trackSymbolIsCircleMarker) || (!isVesselMoving && trackSymbolIsCircleMarker))) {
      this.updateTracksymbol(trackSymbol, trackerData, popupHTML);
      return;
    }

    if (trackSymbol) {
      layerGroup.removeLayer(trackSymbol);
    }

    const popup = L.popup({
      closeButton: false,
      autoClose: false,
      offset: [0, -10],
    }).setContent(popupHTML);

    const newTracker = isVesselMoving
      ? new TrackSymbol([trackerData.lat, trackerData.lng], {
          fill: true,
          fillColor: trackerData.sourceType?.color ?? trackerData.color,
          weight: 1,
          fillOpacity: 1,
          heading: ((trackerData.heading ?? trackerData.trueHeading!) * Math.PI) / 180.0,
          course: (trackerData.course * Math.PI) / 180.0,
          speed: trackerData.speed,
          pane: 'vesselPane',
        }).bindPopup(popup)
      : new L.CircleMarker([trackerData.lat, trackerData.lng], {
          fill: true,
          fillColor: trackerData.sourceType?.color ?? trackerData.color,
          fillOpacity: 1,
          radius: 5,
          weight: 1.5,
        });

    newTracker.on('click', (e) => {
      newTracker.closePopup();
      trackerData.lat = e.latlng.lat;
      trackerData.lng = e.latlng.lng;
      this.selectedMapTrackerData.set(trackerData);
      L.DomEvent.stopPropagation(e);
    });

    newTracker.on('mouseover', () => {
      if (this.selectedMapTrackerData()?.mmsi != trackerData.mmsi) newTracker.openPopup();
    });
    newTracker.on('mouseout', () => newTracker.closePopup());

    layerGroup.addLayer(newTracker);
    trackerAdded(newTracker);
  }

  public updateSelectedMapTracker(newTrackerData: MapTrackerData) {
    const currentMapTracker = this.selectedMapTrackerData();
    if (newTrackerData.mmsi === currentMapTracker?.mmsi) {
      Object.assign(currentMapTracker, newTrackerData);

      this.changeHighlightLocation(newTrackerData.lat, newTrackerData.lng);
    }
  }

  public changeHighlightLocation(lat: number, lng: number) {
    if (this.highlightCircle) {
      this.highlightCircle.setLatLng([lat, lng]);
    }
  }

  private showHighlightCircle(latLng: L.LatLng | undefined) {
    const currentLatLng = latLng ?? this.highlightCircle?.getLatLng();
    if (this.highlightCircle) this.removeHighlightCircle();

    if (!currentLatLng) return;

    // Create and add the highlight circle around the marker
    this.highlightCircle = L.circle(currentLatLng, {
      radius: this.getDynamicRadius(), // Adjust as needed for visual preference
      color: 'blue', // Set the color for the highlight circle
      weight: 3,
      fillOpacity: 0.1,
    }).addTo(this.Map!);
  }

  private removeHighlightCircle() {
    // Remove the highlight circle if it exists
    if (this.highlightCircle) {
      this.Map?.removeLayer(this.highlightCircle);
      this.highlightCircle = undefined;
    }
  }

  private getDynamicRadius(): number {
    // Define the radius adjustment based on zoom level
    const zoomLevel = this.Map?.getZoom() ?? 13; // Default to 13 if map or zoom is undefined

    // Example radius scaling: adjust the base radius as desired
    const baseRadius = 200; // Base radius at zoom level 13
    const scaleFactor = Math.pow(2, 13 - zoomLevel); // Adjust radius exponentially with zoom

    return baseRadius * scaleFactor;
  }

  public fitVessels(vessels: VesselToFollow[]) {
    if (vessels.length === 0 || !this.Map) return;
    const allHistories = vessels.flatMap((vessel) => vessel.histories);
    if (allHistories.length === 0) return;

    this.Map.setView([allHistories[0].lat!, allHistories[0].lng!], this.Map.getZoom());
    const bounds = L.latLngBounds(allHistories.map((coord) => [coord.lat!, coord.lng!]));
    // Adjust the map view to fit the bounds
    this.Map.fitBounds(bounds, { padding: [15, 15], maxZoom: 16 });
  }
}
