import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ThemeService {
  public activeTheme$ = new BehaviorSubject<string>('light');
  public readonly greenColors = ['#2f8a56', '#006400', '#004700', '#81b99a', '#00CF00', '#47FF47'];

  constructor() {
    const theme = localStorage.getItem('theme');
    if (theme === null) {
      localStorage.setItem('theme', 'light');
      return;
    }

    this.activeTheme$.next(theme);
  }

  public toggleTheme(): void {
    const theme = this.activeTheme$.value === 'dark' ? 'light' : 'dark';

    localStorage.setItem('theme', theme);
    this.activeTheme$.next(theme);
    this.setTheme();
  }

  public setTheme(): void {
    const theme = this.activeTheme$.value;
    document.documentElement.setAttribute('data-bs-theme', this.activeTheme$.value);

    const themeLink = document.getElementById('app-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `theme-${theme}.css`;
    }
  }

  public getColorForVesselType(type: string): string {
    switch (type) {
      case 'AIS':
        return '#78D0E7';
      case 'CALYPSO':
        return '#EC8E2F';
      case 'RADAR':
        return '#E1234A';
    }

    //TODO: Default color?
    return '';
  }
}
