import { GetObjectHistoryResponse } from '@dotocean/virtualworld-ngx-services';
import { Polyline } from 'leaflet';
import moment from 'moment';
import { VesselInfo } from '../../../services/apis/cloud.service';

export class VesselToFollow {
  constructor(vesselInfo: VesselInfo, isTarget: boolean, histories: GetObjectHistoryResponse[], start?: Date, end?: Date, color?: string) {
    this.vesselInfo = vesselInfo;
    this.isTarget = isTarget;

    this.start = moment.utc(start);
    this.end = moment.utc(end);

    this.histories = [
      ...histories
        .filter((h) => h.lat && h.lng && h.time)
        .map((h) => ({ ...h, time: h.time ? new Date(h.time) : undefined }))
        .sort((a, b) => a.time!.getTime() - b.time!.getTime()),
    ];
    this.color = isTarget ? '#db163d' : color ?? '#' + Math.floor(Math.random() * 16777215).toString(16);
    this.foreground = isTarget ? 'white' : 'black';
  }
  public vesselInfo: VesselInfo;
  public vesselTrackerSegments: Polyline[] = [];
  public color: string;
  public foreground: string;
  public isTarget = false;

  public start: moment.Moment;
  public end: moment.Moment;

  public currentHeading = 0;
  public readonly histories: GetObjectHistoryResponse[];
}
