// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="proj4" />
import proj4 from 'proj4';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Geographic {
  export declare type xy = number[];
  export declare interface LatLng {
    lat: number;
    lng: number;
  }

  export class UTMProjection {
    private readonly UTM_PROJ: string;

    constructor(zone: number) {
      this.UTM_PROJ = this.getUTMProj4Projection(zone);
    }

    public transform2xy(points: LatLng[]): xy[] {
      const result: xy[] = [];
      for (const e of points) {
        const pt = proj4(this.UTM_PROJ).forward([e.lng, e.lat]);
        result.push(pt);
      }
      return result;
    }

    public transform2LatLng(points: xy[]): LatLng[] {
      const result: LatLng[] = [];
      for (const e of points) {
        const pt = proj4(this.UTM_PROJ).inverse(e);
        result.push({ lng: pt[0], lat: pt[1] });
      }
      return result;
    }

    private getUTMProj4Projection(zone: number) {
      return `+proj=utm +zone=${zone} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
    }
    public static getUTMZone(lat: number, lng: number) {
      let result = Math.floor((lng + 180) / 6) + 1;

      if (lat >= 56.0 && lat < 64.0 && lng >= 3.0 && lng < 12.0) result = 32;
      // Special zones for Svalbard
      if (lat >= 72.0 && lat < 84.0) {
        if (lng >= 0.0 && lng < 9.0) result = 31;
        else if (lng >= 9.0 && lng < 21.0) result = 33;
        else if (lng >= 21.0 && lng < 33.0) result = 35;
        else if (lng >= 33.0 && lng < 42.0) result = 37;
      }
      return result;
    }
  }
}
