{
  "textStyle": {
    "color": "#fcfcfc"
  },
  "title": {
    "textStyle": {
      "color": "#fcfcfc"
    }
  },
  "axisLabel": {
    "textStyle": {
      "color": "#fcfcfc"
    }
  },
  "legend": {
    "textStyle": {
      "color": "#fcfcfc"
    }
  }
}
